export default () => ({
    isLoading: true,
    entries: [
        {
            id: '1',
            date: new Date().toDateString(),
            text: 'lorenNon Lorem id qui nisi culpa excepteur aute velit ea voluptate nisi enim.',
            picture: null,
        },
        {
            id: '2',
            date: new Date().toDateString(),
            text: 'Eu cillum nulla aute minim eiusmod do.',
            picture: null,
        },
        {
            id: '3',
            date: new Date().toDateString(),
            text: 'Incididunt consectetur nostrud qui eu nulla.',
            picture: null,
        }
    ]
})