// export const myAction = ( state ) => {

// }

export const setEntries = ( /* state */ ) => {

}

export const updateEntry = ( /* state */ ) => {

}

export const addEntries = ( /* state */ ) => {

}
